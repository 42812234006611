@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

.resources-section {
  display: flex;
  padding: 20px;
  background-color: #ffffff;
}

.sidebar {
  flex: 1;
  background-color: #f5f5f5;
  padding: 20px;
  margin-right: 20px;
  border-radius: 10px;
  margin-top: 90px;
}

.sidebar-list {
  list-style: none;
  padding: 0;
}

.sidebar-list li {
  margin-bottom: 10px;
}

.sidebar-list a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.contact-box {
  margin-top: 20px;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 10px;
}

.contact-box h3 {
  margin-top: 0;
}

.contact-box a {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #007bff;
}

.main-content {
    flex: 3;
    margin-top: 60px; /* Sayfayı aşağıya kaydırmak için margin-top ekliyoruz */
  }
 
.main-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.resources-content {
  font-size: 1.2rem;
}

.resources-content h2 {
  font-size: 1.5rem;
  margin-top: 20px;
}

.resources-content ul {
  list-style: none;
  padding: 0;
}

.resources-content ul li {
  margin-bottom: 10px;
}

.resources-content ul li a {
  text-decoration: none;
  color: #000;
}

.resources-content ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .resources-section {
    flex-direction: column;
  }

  .sidebar {
    margin-right: 0;
    margin-bottom: 20px;
  }
}