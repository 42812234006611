@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  background-color: #fff;
  margin-top: 100px; /* Header ile araya boşluk ekler */
}

.contact-form {
  flex: 2;
  max-width: 600px;
  margin-right: 50px;
}

.contact-form h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.contact-form p {
  font-size: 1rem;
  margin-bottom: 40px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  font-size: 1rem;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #333;
}

.contact-info {
  flex: 1;
  max-width: 400px;
  background-color: #000;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 50px; /* Header ile arka plan arasında boşluk ekler */
}

.contact-info h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.contact-info ul {
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
}

.contact-info ul li {
  margin-bottom: 10px;
}

.contact-info ul li a {
  color: #61dafb;
  text-decoration: none;
}

.contact-info ul li a:hover {
  text-decoration: underline;
}

.contact-info p {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 50px; /* Mobil cihazlarda header ile araya boşluk ekler */
  }

  .contact-form {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .contact-info {
    max-width: 100%;
    margin-top: 0;
  }
}
