@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

.about-us {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 120px 20px; /* Üst ve alt padding */
  background-color: #f5f5f5; /* Arka plan rengi */
  color: #333; /* Metin rengi */
  position: relative; /* scroll-button için relative konum */
}

.about-text {
  flex: 1;
  padding-right: 20px;
}

.about-text h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-text p {
  font-size: 1.2rem;
  line-height: 1.6;
}

.about-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

.about-divider hr {
  width: 1px;
  height: 80%;
  background-color: #333;
  border: none;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Köşeleri yuvarlama */
  object-fit: cover;
}

.scroll-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000; /* Buton rengi */
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-button:hover {
  background-color: #3b3b41;
}

.scroll-button svg {
  color: #fff;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .about-us {
    flex-direction: column;
    text-align: center;
  }

  .about-text {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .about-divider {
    padding: 20px 0;
  }

  .about-divider hr {
    height: 1px;
    width: 80%;
  }

  .about-image {
    margin-top: 20px;
  }
}