@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

.product-section {
  padding: 200px 20px;
  background-color: #f5f5f5;
  color: #333;
}

.product {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px; /* Ürünler arasında boşluk */
}

.product-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-right: 20px; /* Ürün özelliklerinden uzaklaştırma */
}

.product-info {
  flex: 1;
  text-align: center;
  padding: 0 20px;
}

.product-info h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.product-info h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.product-info p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.product-info ul {
  list-style: none;
  padding: 0;
}

.product-info ul li {
  font-size: 1.2rem;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.main-image-container {
  text-align: center;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px; /* Slider ile fotoğraf arasına boşluk */
}

.main-image-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.main-image-wrapper img {
  min-width: 100%;
  border-radius: 20px;
}

.product-images-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap; /* Öğeleri sarma */
  width: 100%; /* Slider genişliği */
  overflow-x: auto; /* Yatay kaydırma */
}

.product-images-slider .prev-button,
.product-images-slider .next-button {
  background-color: #000000;
  color: #fff;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.product-images-slider .prev-button:hover,
.product-images-slider .next-button:hover {
  background-color: #333;
}

.slider-image {
  width: 120px;
  height: auto;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
  border-radius: 20px;
}

.slider-image.active,
.slider-image:hover {
  opacity: 1;
}

.platforms {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px; /* Butonlar arasına boşluk */
  flex-wrap: wrap;
}

.platform-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.platform-button:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .product {
    flex-direction: column;
    align-items: center; /* Ortala */
  }

  .product-info {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .main-image-container {
    width: 100%;
    max-width: none;
  }

  .main-image-wrapper img {
    max-height: 200px; /* Görüntü yüksekliğini sınırlı tut */
    width: 100%; /* Genişliği %100 yaparak taşmayı önle */
  }

  .product-images-slider {
    width: 100%;
    overflow-x: scroll; /* Yatay kaydırmayı etkinleştir */
  }

  .product-images-slider .prev-button,
  .product-images-slider .next-button {
    font-size: 1rem; /* Buton boyutunu küçült */
    padding: 5px; /* Buton padding'ini küçült */
    margin: 0 5px;
  }

  .slider-image {
    width: 80px; /* Görüntü boyutunu küçült */
  }

  .platforms {
    flex-direction: row; /* Butonları yan yana sıralamak için */
    justify-content: center;
    gap: 5px; /* Butonlar arasına biraz boşluk */
  }

  .dropdown-menu {
    flex-direction: column;
    top: 40px;
    transform: translateX(0);
    width: 100%;
    left: 0;
  }

  .dropdown-menu button {
    margin: 5px 0;
  }
}
