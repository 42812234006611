@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

.product-detail-section {
  padding: 100px 20px;
  background-color: #f5f5f5;
  color: #333;
}

.product-detail-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.product-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image-container {
  text-align: center;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin-bottom: 10px;
}

.main-image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-images-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.product-images-slider .prev-button,
.product-images-slider .next-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 50%;
  margin: 0 10px;
  transition: background-color 0.3s ease;
}

.product-images-slider .prev-button:hover,
.product-images-slider .next-button:hover {
  background-color: #333;
}

.slider-wrapper {
  display: flex;
  overflow: hidden;
  width: 300px; /* Slider genişliği */
}

.slider-image {
  width: 100px;
  height: auto;
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease, transform 0.3s ease;
  border-radius: 10px;
}

.slider-image.active,
.slider-image:hover {
  opacity: 1;
  transform: scale(1.05); /* Hafif büyütme efekti */
}

.product-info {
  flex: 1;
  padding: 0 20px;
}

.product-info h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.product-info h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.product-info p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.product-info ul {
  list-style: none;
  padding: 0;
}

.product-info ul li {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.platforms {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.platform-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.platform-button:hover {
  background-color: #333;
}

.tabs-section {
  background-color: #f5f5f5;
  padding: 20px 0;
}

.tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.tab {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab.active {
  background-color: #fff;
  color: #000;
  border-bottom: 2px solid #000;
}

.tab-content {
  margin-top: 20px;
}

.tab-pane-container {
  min-height: 250px; /* Sabit yükseklik */
}

.tab-pane {
  display: none;
  transition: visibility 0s, height 0.3s ease-in-out;
}

.tab-pane.active {
  display: block;
}

.tab-pane h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  text-align: center; /* Başlık ortalama */
}

.tab-pane ul {
  list-style: none;
  padding: 0;
  text-align: center; /* İçeriği ortalama */
}

.tab-pane ul li {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .product-detail-container {
    flex-direction: column;
    align-items: center;
  }

  .product-info {
    padding: 20px 0;
    text-align: center;
  }

  .main-image-container {
    width: 100%;
    max-width: none;
  }

  .main-image-container img {
    max-height: 200px;
  }

  .slider-wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  .slider-image {
    width: 80px;
    margin: 0 5px;
  }

  .prev-button, .next-button {
    padding: 5px;
    font-size: 1rem;
  }

  .tabs {
    flex-direction: column;
    gap: 5px; /* Butonlar arasındaki boşluk */
  }

  .tab {
    width: 100%;
    text-align: center;
    font-size: 0.9rem; /* Yazı boyutu küçültüldü */
    padding: 8px 0; /* İçerik boşluğu küçültüldü */
  }
  
  .tab.active {
    border-bottom: 2px solid #000; /* Aktif butonun altına çizgi ekle */
  }

  .tab-pane-container {
    padding: 0 10px;
  }
}
