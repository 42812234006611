@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'League Spartan', sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px; /* Padding değerlerini düşürdük */
  background-color: rgba(0, 0, 0, 0); /* Tamamen transparan */
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, color 0.3s ease; /* Geçiş efekti */
}

.header.scrolled {
  background-color: #fff; /* Scroll olduğunda beyaz arka plan */
  color: #000; /* Metin rengini de değiştir */
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 150px; /* Logonun boyutunu küçülttük */
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px; /* Aralarındaki boşluğu azalttık */
  margin: 0;
  padding: 0;
  flex: 1;
  justify-content: center;
}

.nav-links a, .nav-links span {
  color: inherit; /* Metin rengini header'ın renginden al */
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
  cursor: pointer; /* Tıklama imlecini ayarla */
}

.nav-links a:hover, .nav-links span:hover {
  color: #61dafb;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8); /* Transparan beyaz */
  min-width: 160px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 50px; /* Üstten boşluğu azalttık */
    left: 0;
    width: 100%;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-links.mobile-menu-open {
    display: flex;
  }

  .nav-links a, .nav-links span {
    color: #000; /* Mobil menüde metin rengini siyah yap */
  }

  .menu-icon {
    display: block;
  }

  .header {
    justify-content: space-between;
    padding: 10px 20px; /* Padding değerlerini düşürdük */
  }

  .header.scrolled {
    background-color: #fff;
  }

  .logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .dropdown-content {
    background-color: #fff; /* Mobilde beyaz arka plan */
  }
}
