@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;700&display=swap');

body {
  font-family: 'League Spartan', sans-serif;
}

.about-us-team {
  padding: 100px 20px;
  background-color: #000000;
  text-align: center;
}

.about-us-team h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #ffffff;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
}

.team-member-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.team-member h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.team-member p {
  font-size: 1rem;
  color: #ffffff;
}

@media (max-width: 768px) {
  .team-members {
    flex-direction: column;
    gap: 20px;
  }
}